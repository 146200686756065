import React from 'react';
import TableNumber from 'components/Numbers';
import { useStyles } from './styles';

const TableTopThree = ({data}) => {
  const classes = useStyles()
  return <div className={classes.winners}>
      <div className={classes.winners__table__wrapper}>
        {
          data.map((item, index)=> {
            return <div key={index} className={classes.winners__table}>
                <TableNumber data={item} index={index} />
            </div>
          })
        }
      </div>
  </div>
};

export default TableTopThree;

import { makeStyles } from "@material-ui/core/styles";
import { layout, font } from "config/styles";

export const useStyles = makeStyles((theme) => ({
	footer: {
		display: 'flex',
		justifyContent: 'center',
		height: layout.footerHeight,
		backgroundColor: '#af5b35',

		[theme.breakpoints.down('sm')]: {
			height: 'auto',
			padding: '1vw 0',
		}
	},
	footerClass: {
		display: 'flex',
		width: "100%",
		height: "100%",
		alignItems: 'center',
		justifyContent: 'center',
		flexDirection: 'column',
		textAlign: 'center',
	},

	footerLeft: {
		width: "100%",
		display: 'flex',
		alignItems: "center",
		justifyContent: 'center'
	},
	footerTextLeft: {
		display: 'flex',
		alignItems: "center",
		justifyContent: 'center',
		flexDirection: 'row',
		flexWrap: 'wrap',
		fontSize: font.smallSize,
		margin: '0px 20px 0 20px',
		[theme.breakpoints.down('sm')]: {
			fontSize: font.smallSize__mobile,
		}
	},

	footerRight: {
		width: "100%",
		display: 'flex',
		alignItems: "center",
		justifyContent: 'center',
		fontSize: font.smallSize,

		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5vw',
			alignItems: "center",
		}
	},
	
	imageItem: {
		height: '60px',
		margin: '5px',
		[theme.breakpoints.down('sm')]: {
			height: '50px',
		},
		[theme.breakpoints.down('xs')]: {
			height: '8vw',
		}
	}
}));

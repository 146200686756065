import { makeStyles } from "@material-ui/core/styles";

import { color, font } from "config/styles";

export const useStyles = makeStyles((theme) => ({
  container_page: {
    [theme.breakpoints.down('sm')]: {
      backgroundColor: color.mainBackground
    }
  },
  about_title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textTransform: 'uppercase',
    '& span': {
      padding:'5px',
      fontSize: font.largeSize,
      fontFamily: font.boldFamily,
      [theme.breakpoints.down('sm')]: {
        fontSize: font.largeSize__mobile,
      }
    },
    [theme.breakpoints.down('sm')]: {
      margin: '0 20px 0 20px',
    }

  },

  lable_image: {
    height: '12px',
    width: 'auto',
    margin:'2px 2px 0px 0px'
  },

  content: {
    display: 'flex',
    margin: '1px',
    color: 'black',
    fontSize: font.nomalSize,
    '& span': {
      backgroundColor: 'white',
      margin: '1px',
      textAlign: 'left',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: font.nomalSize__mobile,
      margin: '0 20px 0 20px',
    }
  },
  content_center: {
    backgroundColor:'white',
    fontSize: font.nomalSize,
    margin: '10px',
    whiteSpace: 'pre-wrap',
    [theme.breakpoints.down('sm')]: {
      fontSize: font.nomalSize__mobile,
      margin: '0 20px 0 20px',
    }
  }
}));

import { createContext } from 'react';
import { map } from 'lodash';
import Api from 'services/Api';
import { stringToArray } from './../utils'

const mapDataHomeResult = function (data) {
	const prizeList = map(data, (item, index) => {
		return {
			...item,
			date: item.date,
			dayOfWeek: item.dayOfWeek,
			period: item.prize,
			index: index,
			numbers: stringToArray(item.result)
		}
	})
	return {
		prizeList
	}
}
export const AuthContext = createContext({
	homeFunc: null,
	dataList: {},
	resultHomeData: {
		prizeList: []
	},
	mapDataHomePage: mapDataHomeResult,
	requestAuth: Api.requestPageResult,
	requestPeriodDateDropList: Api.requestPeriodDateList
});

import React, { useState, useEffect} from "react";
import { get, map } from 'lodash';
import {stringToArray} from './../../utils'
import { useTranslation } from 'react-i18next';
import Api from 'services/Api';

import TableTopThreeView from './../Home/TableTopThree'
import TableWinners from './../Home/TableWinners'
import { useStyles } from './styles';

const Results = () => {
	const [dataMap, setDataMap] = useState({
		drawTime: {},
		prizeList: [],
		singlePrizes: []
	})

	const classes = useStyles();
	const { t } = useTranslation();

	const generateNumbers = (amount)=> {
		let numbers = ''
		for (let index = 0; index < amount; index++) {
			numbers += Math.floor(Math.random() * 10);
		}
		return numbers
	}

	const handleUpdateAgain = (data)=> {
		const dMap = mapDataToTable(data)
		setDataMap(dMap);
	}

	const mapDataToTable = (data) => {
		const drawTime = get(data, 'period', {})
		const results = get(data, 'result', [])
		const prizeListData = get(results[0], 'prizeList', [])
		const singlePrizeListData = get(results[0], 'singlePrizeList', [])
		let stringNumbers = generateNumbers(6)
		let IsRepeatRandom = false
		const singlePrizes = map(singlePrizeListData, (item, index) => {
			if (item.result === '') {
				IsRepeatRandom = true
				return {
					...item,
					result: stringNumbers,
					index: index + 1,
					numbers: stringToArray(stringNumbers)
				}
			}
			return {
				...item,
				index: index + 1,
				numbers: stringToArray(item.result)
			}
		})

		const prizeList = map(prizeListData, (item, index) => {
			return {
				...item,
				id: index,
				resuls: item.result.map((ressultString) => {
					if (ressultString === '') {
						IsRepeatRandom = true
						return stringToArray(stringNumbers)	
					}
					return stringToArray(ressultString)
				})
			}
		})
		
		if (IsRepeatRandom) {
			//save to random again
			setTimeout(()=>handleUpdateAgain(data), 100)
		}

		return {
			drawTime: {
				date: `${t('LiveDrawResult')} ${drawTime.date}`,
				time: `${t('At')} ${drawTime.time}`
			},
			singlePrizes,
			prizeList
		}
	}

	useEffect(() => {
		Api.requestLives().then((res) => {
			const isSuccess = get(res, 'data.success', false);
			if (!isSuccess) {
				// can not get data
				//render maintainer page
			} else {
				const data = get(res, 'data.data', {});
				const dMap = mapDataToTable(data)
				setDataMap(dMap);
			}
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [t])

	return (
		<div className={classes.container_page} style={{ animation: 'fadeIn ease-in .1s' }}>
			<div className={classes.container}>
				<div className={classes.timeLiveDraw} >
					<div className={classes.liveScoreSmall}>{dataMap.drawTime.date}</div>
					<div className={classes.liveScoreSmaller}>{dataMap.drawTime.time}</div>
				</div>
				<TableTopThreeView data={dataMap.singlePrizes} />
				{map(dataMap.prizeList, (item, index) => {
					return <TableWinners key={index} index={index} data={item} />
				})}
			</div>
		</div>
	)
};

export default Results

import React from 'react';
import { useStyles } from './styles';
import Image from 'react-image-webp';

import Top1 from 'assets/img/common/top_1.png';
import Top1Wepb from 'assets/imgWebp/top_1.webp';
import Top2 from 'assets/img/common/top_2.png';
import Top2Wepb from 'assets/imgWebp/top_2.webp';
import Top3 from 'assets/img/common/top_3.png';
import Top3Wepb from 'assets/imgWebp/top_3.webp';

const imagesTop = [Top1, Top2, Top3]
const imagesWepbTop = [Top1Wepb, Top2Wepb, Top3Wepb]
const backgroundColors = ['#d13c3e', '#64ac48', '#2c8ebb']
const Numbers = ({ data = {}, index = 0 }) => {
	const classes = useStyles();
	const backgroundStyle = {backgroundColor: backgroundColors[index]}

	const renderHeader = () => {
		const { id, name } = data;
		const classNameRainbow = `header_title_${id}`
		return (
			<div className={classes.header_title}> <span className={classNameRainbow}> {name}</span> </div>
		)
	};

	const renderBody = () => {
		const { numbers } = data;
		if (numbers) {
			return numbers.map((num, i) => {
				return <div key={i} className={classes.number_content} style={backgroundStyle}>
					{num}
					</div>
			})
		}
		return null;
	};

	

	return <div className={classes.numbers}>
		<div className={classes.number_title} style={backgroundStyle}>
			<Image className={classes.imageTop} alt='button prev' src={imagesTop[index]} webp={imagesWepbTop[index]} />
			{renderHeader()}
		</div>
		<div className={classes.number__body}>
			<div className={classes.number__body_background} style={backgroundStyle} />
			{renderBody()}
		</div>
	</div>
};

export default Numbers;

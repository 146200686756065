import React from "react";
import { useTranslation } from 'react-i18next';
import { useStyles } from './styles';

const AboutUs = () => {
  const classes = useStyles()
  const {t} = useTranslation();
  
  return (
    <div className={classes.container_page} style={{animation: 'fadeIn ease-in .1s'}}>
      <div className={classes.about_title}>
          <span>
          {t('AboutUs')}</span>
      </div>
      <div className={classes.content}>
        <span>
          <pre className={classes.content_center}>
            {t('AboutUsContent')}
          </pre>
          
        </span>
      </div>
      <br />
    </div>
  )
};

export default AboutUs

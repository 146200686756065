import { makeStyles } from "@material-ui/core/styles";

import {font } from "config/styles";

export const useStyles = makeStyles((theme) => ({
  winners: {
    width: '100%',
    padding: '5px'
  },


  winners__table__wrapper: props => ( {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
    [theme.breakpoints.down('sm')]: {
      fontSize: font.nomalSize__mobile,
    },
  }),

  winners__table: {
    display: 'inline-block',
    width: '100%',
    margin: '1px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  winner_title: {
    position: 'relative',
    display: 'flex',
    alignItems: 'flex-end',
    marginLeft: '10px',
    '& span': {
      fontFamily: font.boldFamily,
      margin: '0px',
      height: '35px',
      color: '#494847',
      fontSize: font.nomalSize,
      [theme.breakpoints.down('sm')]: {
        fontSize: font.nomalSize__mobile,
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: font.largeSize__mobile,
      }
    }
  },

  image_title: {
    height: '12px',
    width: 'auto',
    margin:'2px 2px 0px 2px',
    [theme.breakpoints.down('sm')]: {
      margin:'2px 2px 0px 2px',
    }
  },
}));

import { makeStyles } from "@material-ui/core/styles";

import { color, font } from "config/styles";

export const useStyles = makeStyles((theme) => ({
  table: {
    width: '100%',
    borderSpacing: '0px',
    overflow: 'hidden',
    '& th, td': {
    },
    '& table': {
      borderCollapse: 'collapse',
      overflow: 'hidden'
    },
    fontSize: font.largeSize,
    [theme.breakpoints.down('sm')]: {
      fontSize: font.largeSize__mobile,
    },
  },

  table__header: {
    '& tr': {
      height: '60px',
      backgroundColor: color.tableHeader,
    },
    '& th': {
      textAlign: 'left',
    },

    [theme.breakpoints.down('sm')]: {
      '& tr': {
        height: '9.375vw',
      },
    },
  },

  table__body: {
    '& tr': {
      height: '40px',
    },

    [theme.breakpoints.down('sm')]: {
      '& tr': {
        height: '6.25vw',
      },
    },
  },
  table_container_numers: {
    display: 'flex',
    alignItems:'center',
    justifyContent: 'center',
    flexDirection: 'colum',
    marginTop:'5px',
    marginBottom:'5px',
  },
  table_number: {
    display: 'flex',
    alignItems:'center',
    justifyContent: 'center',
    borderRadius: '50%',
    width: '40px',
    heigh: '40px',
    fontSize: '28px',
    padding: '1px',
    margin: '5px',
    backgroundColor: '#de9359',
    fontFamily: font.boldFamily,
    boxSizing: 'content-box',
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
      width: '28px',
      heigh: '28px',
      margin: '1px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '3vw',
      width: '3.8vw',
      heigh: '3.8vw',
      margin: '1px',
    }
  }
}));

import { makeStyles } from "@material-ui/core/styles";
import { color, font } from "config/styles";

export const useStyles = makeStyles((theme) => ({
  winners: {
    position: 'relative',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '1560px',
      width: '100%',
      
    },
  },

  winners__table__wrapper: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-around',
    [theme.breakpoints.down('sm')]: {
      fontSize: font.nomalSize__mobile,
    },
  },

  winners__table: {
    display: 'flex',
    justifyContent: 'center',
    flex: '1',
    backgroundColor: color.tableBackground,
    
  },
  winner1stCol: {
    paddingLeft: '20px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '6.25vw',
    },
  },
}));

import { makeStyles } from "@material-ui/core/styles";
import { color, font } from "config/styles";

export const useStyles = makeStyles((theme) => ({
  container_page: {
    [theme.breakpoints.down('sm')]: {
      backgroundColor: color.mainBackground
    }
  },
  menus: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    height: '65px',
    marginBottom: '10px',
    [theme.breakpoints.down('sm')]: {
      height: '50px'
    },
    [theme.breakpoints.down('xs')]: {
      height: '40px'
    }
  },

  menuBackground: {
    position: 'absolute',
    top: '0',
    left: '0',
    display: 'flex',
    width: '100%',
    height: '100%',
    backgroundColor: '#ddd9d9',
    opacity: '0.3',
    zIndex: '0',
  },

  menuItem : {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'uppercase',
    fontSize: font.nomalSize,
    fontFamily: font.boldFamily,
    width: '14.2%',
    height: '100%',
    margin: '0px 5px 0px 5px',
    cursor: 'pointer',
    zIndex: '1',
    [theme.breakpoints.down('sm')]: {
      fontSize: font.nomalSize__mobile,
    }
  },
  menuItemSelected: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'uppercase',
    fontSize: font.nomalSize,
    fontFamily: font.boldFamily,
    width: '14.2%',
    height: '100%',
    cursor: 'pointer',
    zIndex: '1',
    [theme.breakpoints.down('sm')]: {
      fontSize: font.nomalSize__mobile,
    },
    backgroundColor: '#d03d3d',
  },
}));

import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from 'context/AuthContext'
import { useTranslation } from 'react-i18next';
import { map, get } from 'lodash';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import RTable from './../Results/RTable';
import { useStyles } from './styles';
import Api from 'services/Api';


const Home = (props) => {
	const classes = useStyles()
	const theme = useTheme();
  	const matches = useMediaQuery(theme.breakpoints.down('sm'));
	const authContent = useContext(AuthContext);
	const { resultHomeData, mapDataHomePage } = authContent
	const [ prizeList, SetPrizList] = useState([])
	const [daySelected, setDaySelected] = useState('')
	const {t} = useTranslation()
	
	useEffect(()=> {
		if (daySelected === '') {
			SetPrizList(resultHomeData.prizeList)
		}
		authContent.homeFunc = () => {
			setDaySelected('')
		}
		
	}, [authContent.homeFunc, daySelected, resultHomeData.prizeList, t])

	const days = ['Mon','Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
	const mapDatatoTable = () => {
		let header = null;
		let body = null;
		if (matches) {
			header = [t('No'), t('Day'), t('Result'), t('Prize')]
			body = map(prizeList, (resul, index) => {
				return [
					resul.index + 1,
					{
						name: 'day',
						dayOfWeek: t(resul.dayOfWeek),
						date: resul.date
					},
					resul.numbers,
					resul.period
				]
			})
		} else {
			header = [t('No'), t('Day'), t('Date'), t('Result'), t('Prize')]
			body = map(prizeList, (resul, index) => {
				return [
					resul.index + 1,
					t(resul.dayOfWeek),
					resul.date,
					resul.numbers,
					resul.period
				]
			})
		}
		
		return {
			header,
			body
		}
	}

	const handleSelected = (select) => {
		if (select !== daySelected) {
			setDaySelected(select);
			Api.requestPageResult(select).then((res) => {
				const isSuccess = get(res, 'data.success', false);
				if (isSuccess) {
					const data = get(res, 'data.data', {});
					const hData = mapDataHomePage(data)
					SetPrizList(hData.prizeList)
				}
			});
		}
	}

	const dataMap = mapDatatoTable()
	return (
		<div className={classes.container_page} style={{ animation: 'fadeIn ease-in .3s' }}>
			<div className={classes.menus}>
				<div className={classes.menuBackground} />
				{
					days.map((day, index) =>
					{	
						let classSelected = classes.menuItem
						if (day === daySelected) {
							classSelected = classes.menuItemSelected
						}
						return <div key={index} className={classSelected} onClick={()=>handleSelected(day)}> {t(day)} </div>
					})
				}
			</div>
			<RTable style={{width: '100%',}} data={dataMap} level={1} />
			<br />
		</div>
	)
};

export default Home

/* eslint-disable react/jsx-fragments */
import React, { useCallback, useState } from 'react';
import parse from 'html-react-parser';
import { Helmet } from 'react-helmet';
import toString from 'lodash/toString';
import map from 'lodash/map';
import ApiMeta from './ApiMeta';
import Api from './Api';
import useRouter from '../shared/hooks/useRouter';

const MetaData = () => {
	const [metadata, setMetadata] = useState({});
	const router = useRouter();

	const onChangeClientState = useCallback((newState) => {
		const removeElements = (elements) => {
			map(elements, el => el.remove());
		};

		map(newState.metaTags, (tag) => {
			if (tag.name) {
				const names = document.querySelectorAll(`meta[name="${tag.name}"]:not([data-react-helmet="true"])`);
				removeElements(names);
			}
			if (tag.property) {
				const propertys = document.querySelectorAll(`meta[property="${tag.property}"]:not([data-react-helmet="true"])`);
				removeElements(propertys);
			}
		});
	}, []);

	return (
		<>
			<ApiMeta
				apiFunc={() => Api.requestMeta(router.pathname.replace(/^\//, ''))}
				setData={setMetadata}
				deps={[true, router.pathname]} loaderEffect={false}
				errorPopup={false}
			/>
			<Helmet
				onChangeClientState={onChangeClientState}
			>
			</Helmet>

			<Helmet>
				{parse(toString(metadata.metadata)
					.replace(/<\\\/title>/g, '</title>')
					.replace(/\t/g, '')
					.replace(/\n/g, '')
					.replace(/\\r/g, '')
					.replace(/\\n/g, '')
					.replace(/\\"/g, '\'')
				)}
			</Helmet>
		</>
	);
};

export default MetaData;

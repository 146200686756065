import React from 'react';
import { map } from 'lodash';
import { useStyles } from './styles';

const width5Columns = ['15%', '20%','20%', '25%','20%']
const width4Columns = ['15%', '20%','40%','25%','20%']

const RTable = ({ data = {}, style }) => {
	const classes = useStyles();
	const renderHeader = () => {
		const { header } = data;
		return <div className={classes.table__header_1} >
			{
				map(header, (head, i) => {
					const style = {}
					if (header.length === 5) {
						style.width =  width5Columns[i]
						if (i !== 0 && i !== 3) {
							style.justifyContent = 'left';
						}
						if (i === 4) {
							style.justifyContent = 'center'
						} else if (i === 2) {
							style.justifyContent = 'left'
						}
					} else {
						style.width =  width4Columns[i]
						style.justifyContent = 'center'
						if (i === 1) {
							style.justifyContent = 'flex-start'
						}
					}
					return <div className={classes.row_header_table} style={style} key={i}>{head}</div>
				})
			}
		</div>
	};

	const renderBody = () => {
		const { body } = data;

		return map(body, (row, i) => {
			let classNameRow = classes.body_bg_1
			if (i % 2 === 0) {
				classNameRow = classes.body_bg_2
			}

			return <div key={i} className={classNameRow}>
				{
					map(row, (data, j) => {
						let style = {};
						if (row.length === 5) {
							style.width =  width5Columns[j]
							if (j !== 0 && j !== 3) {
								style.justifyContent = 'flex-start';
							}
							if (j === 4) {
								style.justifyContent = 'center'
							} else if (j === 2) {
								style.justifyContent = 'flex-start'
							}
						} else {
							style.width =  width4Columns[j]
							style.alignItems = 'center'
							if (j === 1) {
								style.alignItems = 'flex-start'
							}
						}
						if (Array.isArray(data)) {
							return <div style={style} key={j} className={classes.row_table}>
								{map(data, (item, index) => <div className={classes.row_circle} key={index}>{item}</div>)}
							</div>
						} else if (typeof data === "object" && data.name === 'day') {
							return <div style={style} className={classes.row_table_break} key={j}>
								<div className={classes.row_table_sub_top} >{data.dayOfWeek}</div>
								<div className={classes.row_table_sub_bottom} >{data.date}</div>
							</div>
						}
						return <div style={style} className={classes.row_table} key={j}>{data}</div>
					}
					)
				}
			</div>
		})
	};

	return <div className={classes.table} style={style}>
		<div className={classes.table__header}>
			{renderHeader()}
		</div>
		<div className={classes.table__body_1}>
			{renderBody()}
		</div>
	</div>
};

export default RTable;

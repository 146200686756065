import { makeStyles } from "@material-ui/core/styles";
import {font } from "config/styles";

export const useStyles = makeStyles((theme) => ({
  numbers: {
    display: 'flex',
    flex: '1',
    justifyContent:'center',
    alignItems:'center',
    flexDirection: 'column',
    backgroundColor: 'white',
    [theme.breakpoints.down('sm')]: {
      fontSize: font.nomalSize__mobile,
    },
  },

  number_title: {
    display:'flex',
    justifyContent:'flex-start',
    alignItems:'flex-end',
    height: '50px',
    width:'100%',
  },

  header_title: {
    '& span': {
      fontFamily: font.boldFamily,
      fontSize: font.largeSize,
      [theme.breakpoints.down('sm')]: {
        fontSize: font.largeSize__mobile
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: font.bigSize__mobile
      }
    },
  },
  imageTop: {
    height: '100%',
    width: 'auto'
  },

  number__body: {
    position: 'relative',
    display: 'flex',
    width: '100%',
    justifyContent:'center',
    alignItems:'center',
    flexDirection: 'row',
    padding: '15px 0px 15px 0px'
  },

  number__body_background: {
    position: 'absolute',
    display: 'flex',
    width: '100%',
    height: '100%',
    left: '0',
    top: '0',
    opacity: '0.3',
    zIndex: '0',
  },  

  number_content: {
    boxSizing: 'content-box',
    borderRadius: '50%',
    color: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1px',
    margin: '3px',
    width: '40px',
    heigh: '40px',
    fontSize: '28px',
    fontFamily: font.boldFamily,
    zIndex: '1',
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
      width: '28px',
      heigh: '28px',
      margin: '1px',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '3vw',
      width: '3.8vw',
      heigh: '3.8vw',
      margin: '1px',
    }
  }
}));

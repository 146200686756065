const english = {
  translations: {
    "AboutUs": "About Us",
    "Date": "DATE",
    "FooterCopyright": "Copyright © 2015 [company_name]. ALL RIGHTS RESERVED.",
    "Home": "Home",
    "Password": "Password",
    "SUBMIT": "SUBMIT",
    "Username": "Username",
    "headerHome": "Home",
    //end error message
    "Results": 'Results',
    "AboutUsContent": `Lottery game company,
TOTOTHAILAND is owned and operated by a safe and well-known technology company that focuses on software and system development. We are a Lottery company!

High quality service
TOTOTHAILAND is the first choice for players, not only because of the superiority of our products, but also strives to provide the best quality to ensure the safety and comfort of our players. TOTOTHAILAND has a customer service team to solve questions and problems for every esteemed customer without having to rest at home. Now select TOTOTHAILAND, then PLAY SMART!`,
    'Sun': 'Sunday',
    'Mon': 'Monday',
    'Tue': 'Tuesday',
    'Wed': 'Wednesday',
    'Thu': 'Thursday',
    'Fri': 'Friday',
    'Sat': 'Saturday',
    'No': 'NO',
    'Day': 'DAY', 
    'Result': 'RESULT', 
    'Prize': 'PRIZE',
    'Live':	'Live',
    'LiveMenu': 'Live-Draw',
    'LiveDrawResult': 'LIVE DRAW',
    'At': 'at',
    'CompanyName': 'TOTOTHAILAND'

 }
};

export default english;

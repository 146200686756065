import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';
import Loading from '../shared/Loading';

const ApiMeta = ({
	apiFunc = async () => {}, // Promise API function
	setData = () => {}, // Function to update data
	deps = [], // Call API when deps change
	successCallback = () => {}, // Callback when success
	loaderEffect = true, // Show/hide loader UI when call API
	errorPopup = true,
}) => {
	const [loading, setLoading] = useState(false);
	
	useEffect(() => {
		const loadMeta = async () => {
			setLoading(() => true);
			const response = await apiFunc();
			if (get(response, 'data.success', false)) {
				if (isFunction(setData)) {
					setData(get(response, 'data.data', {}));
				}
				if (isFunction(successCallback)) {
					successCallback();
				}
			} else {
				if (errorPopup) {
					// error message handler
				}
				console.log('Error:', response);
			}
			setLoading(() => false);
		};
		loadMeta();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [...deps]);

	return (
		<>
			{loaderEffect && loading && <Loading/>}
		</>
	);
};

export default ApiMeta;

import { makeStyles } from "@material-ui/core/styles";
import {layout } from "config/styles";

export const useStyles = makeStyles((theme) => ({
  containner: {
    position: 'relative',
    width: '100%',
    minHeight: layout.headerHeighBg,
    marginBottom: '5px',
    [theme.breakpoints.down('sm')]: {
      minHeight: layout.headerHeighBg__mobile,
      '& ball__left': {
        display: 'flex'
      }
    },
  },

  img_logo: {
    position: 'absolute',
    left: '0',
    top: '0',
    width: '30%',
    [theme.breakpoints.down('sm')]: {
      transform: 'translateX(-50%)',
      width: '60%',
      left: '50%',
      top: '15%',
    },
  },
}));

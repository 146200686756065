import { makeStyles } from "@material-ui/core/styles";
import { font } from "../../../config/styles";
export const useStyles = makeStyles((theme) => ({
  table: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    width: '100%',
    marginBottom:'2vh',
   '& tr, td': {
      borderSpacing: '0px',
      cellPadding: '0px',  
      padding: '0',
      margin: '0'
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.4vw',
    }
  },

  table__header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    borderRadius: '10px',
    width: '100%',
    '& div': {
      height: '60px',
      width: '100%',
      [theme.breakpoints.down('sm')]: {
        height: '50px'
      },
      [theme.breakpoints.down('xs')]: {
        height: '40px'
      }
      
    },
    '& th': {
      textAlign: 'center',
    },
    '& th:nth-child(1)': {
      borderTopLeftRadius: '6px',
    },
    '& th:nth-child(2)': {
      borderTopRightRadius: '6px',
    },

    [theme.breakpoints.down('sm')]: {
      '& tr': {
        height: '9.375vw',
      },
    },
  },

  table__header_1: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    backgroundColor:'#d03d3d',
    '& div': {
      textAlign: 'center',
      height: '40px',
      // paddingLeft: '10px' 
    },
  },

  row_header_table: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '40px',
    fontFamily: font.boldFamily
  },

  table__body_1: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '& div': {
      textAlign: 'center'
    },
    [theme.breakpoints.down('sm')]: {
      '& tr': {
        height: '6.25vw',
      },
    }
  },

  row_table: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    color: 'black',
  },

  row_table_sub_top: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    color: 'black',
  },

  row_table_sub_bottom: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: 'black',
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.0vw',
      color: 'red'
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '2.2vw',
      color: 'red'
    }
  },

  row_table_break: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    color: 'black',
  },

  row_circle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    width: '30px',
    height: '30px',
    maxHeight: '30xp',
    backgroundColor:'#de9359',
    color: 'white',
    margin:'3px',
    boxSizing: 'content-box',
    fontFamily: font.boldFamily,
    [theme.breakpoints.down('xs')]: {
      width: '4vw',
      height: '4vw',
    }
  },

  body_bg_1: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    height: '40px',
    [theme.breakpoints.down('sm')]: {
      height: '50px',
    },
    [theme.breakpoints.down('xs')]: {
      height: '35px',
    }
  },

  body_bg_2: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-round',
    backgroundColor: '#ddd9d9',
    height: '40px',
    [theme.breakpoints.down('sm')]: {
      height: '50px',
    },
    [theme.breakpoints.down('xs')]: {
      height: '35px',
    }
  }
}));
